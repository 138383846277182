<template>
  <div id="publication-list">
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <CollectionForm
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{
              $t(' Traitement Texte')
            }}</span>
          <v-spacer/>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </CollectionForm>
    </v-dialog>
    <v-dialog
      v-model="isFormAttributionActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <AttributionForm
        v-model="item"
        :is-form-active="isFormAttributionActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{
              $t('Attribuer le dossier')
            }}</span>
          <v-spacer/>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </AttributionForm>
    </v-dialog>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Traitement Texte") }}
        <v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer/>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-title>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        :mobile-breakpoint="250"
        hide-default-footer
        :show-select="false"
        dense
        fixed-header
        :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters"/>
        </template>
        <template #item="options">
          <Row :options="options"
               :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Text' }"
               @refetch-data="fetchItems"
               @saved="saved">
            <!-- name -->
            <template #[`item.author.full_name`]="{item}">
              <div
                v-if="item.author && item.author.id"
                class="d-flex align-center"
              >
                <v-avatar
                  :color="item.author.avatar ? '' : 'primary'"
                  :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.author.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.author.full_name) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  <router-link
                    :to="{ name : 'author-view', params : { id : item.author_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.author.full_name }}
                  </router-link>
                  <!--              <small>@{{ item.publicationname }}</small>-->
                </div>
              </div>
            </template>
            <template #[`item.author.old_author`]="{item}">
              <v-icon
                v-if="item.author && item.author.old_author"
                small
                color="success"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
            </template>
            <template #[`item.author.published`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.published"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <template #[`item.is_text_bat_finished`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.is_text_bat_finished"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <!-- pay -->
            <template #[`item.country`]="{item}">
              <div
                v-if="item.country"
                class="d-flex align-center"
              >
                <span class="text-capitalize">{{ item.country.name }}</span>
              </div>
            </template>

            <template #[`item.completion_date`]="{item}">
              <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{
                  formatDate(item.completion_date || null, {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric'
                  })
                }}</span>
            </template>
            <template #[`item.is_reading1`]="{item}">
              <div
                v-if="$can('edit_is_reading', 'Text')"
                class=" align-center text-center"
              >
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, type:'select',items:[
                      { id: 1, name: '*' },
                    ], selectId: 'id', selectLabel: 'name', action: 'paiement_is_reading',activity_date: item.is_reading,date_column:'is_reading', 'activity_date_label': $t('Texte de qualité'), info: $t('Confirmez-vous que la vérification de la qualité a été faite ?') })"
                    v-on="on"
                  >
                    <span>
                      <v-icon x-small color="primary"
                              v-if="item.is_reading"
                      >
                        {{ icons.mdiCheckCircle }}
                      </v-icon>
                    </span>
                  </span>
                    </template>
                    <span
                      v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'paiement_is_reading').length === 0)">{{
                        $t('Cliquez ici pour mettre à jour')
                      }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_is_reading')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{
                              formatDate(precord.date_record || null, {
                                month: 'numeric',
                                day: 'numeric'
                              })
                            }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="(!item.publication_records || item.publication_records.filter(el=>el.type === 'paiement_is_reading').length <= 0)"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, column_type:'select',items:[
                    { id: 1, name: '*' },
                  ], selectId: 'id', selectLabel: 'name', action: 'paiement_is_reading',activity_date: item.is_reading,date_column:'is_reading', 'activity_date_label': $t('Texte de qualité'), info: $t('Confirmez-vous que la vérification de la qualité a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-icon
                  v-if="item.is_reading"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <!-- status -->
            <template #[`item.page_layout1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'page_layout')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'text_page_layout',activity_date: item.page_layout,date_column:'page_layout', 'activity_date_label': $t('Mise en page'), info: $t('Confirmez-vous que la mise en page a été faite ?') })"
                    v-on="on"
                  ><span v-if="item.page_layout && !item.page_layout.includes('0000')">{{
                      formatDate(item.page_layout || null, {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric'
                      })
                    }}</span></span>
                    </template>
                    <span
                      v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'text_page_layout').length === 0)">{{
                        $t('Cliquez ici pour mettre à jour')
                      }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'text_page_layout' && el.comment)"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{
                              formatDate(precord.date_record || null, {
                                month: 'numeric',
                                day: 'numeric'
                              })
                            }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.page_layout || item.page_layout.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'text_page_layout',activity_date: item.page_layout,date_column:'page_layout', 'activity_date_label': $t('Mise en page'), info: $t('Confirmez-vous que la mise en page a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.correction11`]="{item}">
              <div :style="stateColor(item.publication_colors, 'correction1')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'text_correction1',activity_date: item.correction1,date_column:'correction1', 'activity_date_label': $t('Correction 1'), info: $t('Confirmez-vous que la correction 1 a été faite ?') })"
                    v-on="on"
                  ><span v-if="item.correction1 && !item.correction1.includes('0000')">{{
                      formatDate(item.correction1 || null, {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric'
                      })
                    }}</span></span>
                    </template>
                    <span
                      v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'text_correction1').length === 0)">{{
                        $t('Cliquez ici pour mettre à jour')
                      }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'text_correction1')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{
                              formatDate(precord.date_record || null, {
                                month: 'numeric',
                                day: 'numeric'
                              })
                            }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.correction1 || item.correction1.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'text_correction1',activity_date: item.correction1,date_column:'correction1', 'activity_date_label': $t('Correction 1'), info: $t('Confirmez-vous que la correction 1 a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.correction21`]="{item}">
              <div :style="stateColor(item.publication_colors, 'correction2')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'text_correction2',activity_date: item.correction2,date_column:'correction2', 'activity_date_label': $t('Correction 2'), info: $t('Confirmez-vous que la correction 2 a été faite ?') })"
                    v-on="on"
                  ><span v-if="item.correction2 && !item.correction2.includes('0000')">{{
                      formatDate(item.correction2 || null, {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric'
                      })
                    }}</span></span>
                    </template>
                    <span
                      v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'text_correction2').length === 0)">{{
                        $t('Cliquez ici pour mettre à jour')
                      }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'text_correction2')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{
                              formatDate(precord.date_record || null, {
                                month: 'numeric',
                                day: 'numeric'
                              })
                            }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.correction2 || item.correction2.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'text_correction2',activity_date: item.correction2,date_column:'correction2', 'activity_date_label': $t('Mise en page'), info: $t('Confirmez-vous que la correction 2 a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.send_text_bat1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'send_text_bat')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'text_send_text_bat',activity_date: item.send_text_bat,date_column:'send_text_bat', 'activity_date_label': $t('Envoi BAT texte'), info: $t('Confirmez-vous que l\'envoi BAT texte a été fait ?') })"
                    v-on="on"
                  ><span v-if="item.send_text_bat && !item.send_text_bat.includes('0000')">{{
                      formatDate(item.send_text_bat || null, {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric'
                      })
                    }}</span></span>
                    </template>
                    <span
                      v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'text_send_text_bat').length === 0)">{{
                        $t('Cliquez ici pour mettre à jour')
                      }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'text_send_text_bat')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{
                              formatDate(precord.date_record || null, {
                                month: 'numeric',
                                day: 'numeric'
                              })
                            }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.send_text_bat || item.send_text_bat.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'text_send_text_bat',activity_date: item.send_text_bat,date_column:'send_text_bat', 'activity_date_label': $t('Envoi BAT texte'), info: $t('Confirmez-vous que l\'envoi BAT texte a été fait ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.text_bat1`]="{item}">
              <div
                v-if="item.send_text_bat"
                :style="stateColor(item.publication_colors, 'text_bat')"
              >
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'text_text_bat',activity_date: item.text_bat,date_column:'text_bat', 'activity_date_label': $t('BAT texte'), info: $t('Confirmez-vous que la BAT texte été fait?') })"
                    v-on="on"
                  ><span v-if="item.text_bat && !item.text_bat.includes('0000')">{{
                      formatDate(item.text_bat || null, {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric'
                      })
                    }}</span></span>
                    </template>
                    <span
                      v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'text_text_bat').length === 0)">{{
                        $t('Cliquez ici pour mettre à jour')
                      }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'text_text_bat')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{
                              formatDate(precord.date_record || null, {
                                month: 'numeric',
                                day: 'numeric'
                              })
                            }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.text_bat || item.text_bat.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'text_text_bat',activity_date: item.text_bat,date_column:'text_bat', 'activity_date_label': $t('Envoi BAT texte'), info: $t('Confirmez-vous que la BAT texte été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.quality_text1`]="{item}">
              <div
                :style="stateColor(item.publication_colors, 'is_quality_text')"
              >
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, column_type:'select',items:[
                    { id: 1, name: 'TQ' },
                    { id: 0, name: 'TS' },
                  ], selectId: 'id', selectLabel: 'name', action: 'text_quality_text',activity_date: item.is_quality_text,date_column:'is_quality_text', 'activity_date_label': $t('Qualité du texte'), info: $t('Confirmez-vous que la vérification de la qualité a été faite ?') })"
                  v-on="on"
                >
                  <span
                    v-if="item.is_quality_text > 0"
                  >
                    TQ
                  </span>
                  <span
                    v-if="item.is_quality_text === 0"
                  >
                    TS
                  </span>
                </span>
                    </template>
                    <span
                      v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'text_quality_text').length === 0)">{{
                        $t('Cliquez ici pour mettre à jour')
                      }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'text_quality_text')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user ? precord.user.code : '' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{
                              formatDate(precord.date_record || null, {
                                month: 'numeric',
                                day: 'numeric'
                              })
                            }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.is_quality_text && item.is_quality_text !== 0"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, column_type:'select',items:[
                  { id: 1, name: 'TQ' },
                  { id: 0, name: 'TS' },
                ], selectId: 'id', selectLabel: 'name', action: 'text_quality_text',activity_date: item.quality_text,date_column:'quality_text', 'activity_date_label': $t('Qualité du texte'), info: $t('Confirmez-vous que la vérification de la qualité a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{item}">
              <!--          <v-btn
                color="primary"
                small
                icon
                @click="openDialogAttribution({...item, publication_id: item.id, gestion_id:null, assistant_id: null, task_date: null })"
              >
                <v-icon
                  class="me-2"
                  color="primary"
                >
                  {{ icons.mdiCalendar }}
                </v-icon>
              </v-btn>-->
            </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options"/>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider/>
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer/>
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCalendar,
  mdiCheckboxMarkedCircleOutline,
} from '@mdi/js'
import {onUnmounted, ref} from '@vue/composition-api'

// sidebar
import {avatarText, formatDate} from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import itemStoreModule from './itemStoreModule'
import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import CollectionForm from '@/views/apps/publications/SignatureForm'
import AttributionForm from '@/views/apps/publications/AttributionForm'
import HeaderFilters from '@/components/HeaderFilters'
import ability from '@/plugins/acl/ability'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";

export default {
  components: {
    Row,
    TableRow,
    HeaderFilters,
    AttributionForm,
    CollectionForm,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      saved,
      item,
      items,
      textTableColumns: tableColumns,
      filters,
      tableHead,
      publicationStates,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      tabFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      isFormAttributionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems, tableHeight,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
    } = controller()

    const superiors = ref([])
    const assistants = ref([])

    const payOptions = [
      {title: 'Admin', value: 'admin'},
      {title: 'Publication', value: 'publication'},
      {title: 'Editor', value: 'editor'},
      {title: 'Maintainer', value: 'maintainer'},
      {title: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {title: 'Basic', value: 'basic'},
      {title: 'Company', value: 'company'},
      {title: 'Enterprise', value: 'enterprise'},
      {title: 'Standard', value: 'standard'},
    ]

    const statusOptions = [
      {title: 'Actif', value: 1},
      {title: 'Inactif', value: 0},
    ]
    store
      .dispatch('app-publication/fetchCountries', {per_page: 500})
    store
      .dispatch('app-publication/fetchSources', {per_page: 500})
    store
      .dispatch('app/fetchGenres', {per_page: 500})
    store
      .dispatch('app/fetchFormats', {per_page: 500})
    store
      .dispatch('app-publication/fetchStatuses', {per_page: 500, status_type: 'publication'})
    stateFilter.value = [
      /*publicationStates.value.SIGNED_STATE,*/
      publicationStates.value.COLLECTED_STATE,
      publicationStates.value.TEXT_STATE,
      publicationStates.value.COVER_STATE,
      publicationStates.value.SUPERVISOR_STATE,
      publicationStates.value.PUBLICATION_STATE,
      /*publicationStates.value.PAYMENT_STATE,
      publicationStates.value.RESILIATION_STATE,*/
    ]
    tabFilter.value = 'Text'
    filters.value = tableColumns.filter(ele => !ele.value || ability.can('view_' + ele.value, 'Text'))

    /* filters.value.push(
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ) */

    if (ability.can('assistant', 'Text')) {
      axios
        .get('/listWorkers', {
          params: {
            term: '',
            type: 'text_cover',
            user: JSON.parse(localStorage.getItem('userData')).id || null,
            rowsPerPage: 100,
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]
            const indexU = assistants.value.findIndex(el => el.id === u.id)
            if (indexU < 0) {
              assistants.value.push(u)
            }
          }
          const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
          if (indexH > 0) {
            filters.value[indexH].filter.values = assistants.value
          }
        })
    }

    axios
      .get('/publications/text/settings', {
        params: {},
      })
      .then(response => {
        if (response.data && response.data.errors_before_corrections) {
          const indexH = filters.value.findIndex(ele => ele.value === 'errors_before_correction')
          if (indexH > 0) {
            const errors = filters.value[indexH].filters[0].values = response.data.errors_before_corrections.map(ele => ele.errors_before_correction)

            filters.value[indexH].filters[0].min = errors[0]
            filters.value[indexH].filters[0].max = errors[errors.length - 1]

            // catch for out of range error
            //filters.value[indexH].filters[0].value = [errors[0], errors[errors.length - 1]]
          }
        }
        if (response.data && response.data.error_per_pages) {
          const indexH1 = filters.value.findIndex(ele => ele.value === 'error_per_page')
          if (indexH1 > 0) {
            const errors = filters.value[indexH1].filters[0].values = response.data.error_per_pages.map(ele => ele.error_per_page)

            filters.value[indexH1].filters[0].min = errors[0]
            filters.value[indexH1].filters[0].max = errors[errors.length - 1]

            // catch for out of range error
            //filters.value[indexH1].filters[0].value = [errors[0], errors[errors.length - 1]]

            /* filters.value[indexH1].filters[0].values = response.data.error_per_pages.map((ele) => {
              return {
                id: ele.error_per_page, label: ele.error_per_page,
              }}) */
          }
        }
      })

    axios
      .post('/particular_case/list', { page: 1000 })
      .then(response => {
        //store.state['app-manuscript/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'manuscript.particular_case.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data
          filters.value[indexH].fields[0].items = response.data
        }
      }).catch(error => console.log(error))

    return {
      saved,
      item,
      items,
      superiors,
      tableColumns,
      searchQuery,
      filters,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      tabFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      assistants,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isFormAttributionActive,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      fetchItems, tableHeight,
      tableHead,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCalendar,
        mdiCheckboxMarkedCircleOutline,
      },
    }
  },
}
</script>
<style lang="scss">
/*table {

  background-color: white;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  display: block;
  overflow-x: scroll;
}
.v-data-table td, th {
  margin: 0;
  border: 1px solid grey;
  white-space: nowrap;
  border-top-width: 0px;
}

.v-data-table thead tr:first-child
}
.fixed-column {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  margin-top: -1px;
}*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
